import React from 'react';
import { PageTitle } from 'Components';
import './device.css';
import { DeviceItem } from './components';

const DevicePresenter = ({ deviceList, deviceQty }) => {
  /* Router */
  /* State */
  /* Functions */
  /* Hooks */
  /* Render */
  const deviceRender = deviceList.map((item) => {
    const { theater_id } = item;
    return <DeviceItem key={theater_id} theater={item} />;
  });
  return (
    <div className="device-container">
      <PageTitle title={`기기 목록 (${deviceQty})`} />
      {deviceRender}
    </div>
  );
};

export default DevicePresenter;
