import React, { useCallback, useRef, useState } from 'react';
import './device-item.css';
import { DeviceApi } from 'API';

const DeviceItem = ({ theater }) => {
  const ref = useRef();
  const { theater_theater_nm, devices } = theater;
  const [isCollapse, setIsCollapse] = useState(false);
  const [editingDeviceId, setEditingDeviceId] = useState(null);
  const [editedDevices, setEditedDevices] = useState({});

  const handleFold = useCallback(() => {
    if (!ref.current) {
      return;
    }
    if (isCollapse) {
      ref.current.style.height = `0`;
      ref.current.style.border = `none`;
    } else {
      const d = devices.length;
      ref.current.style.height = `${d * 145}px`;
      ref.current.style.borderBottom = `1px solid #c4c4c4;`;
    }
    setIsCollapse(!isCollapse);
  }, [isCollapse, devices.length]);

  const handleDeleteClick = async (device_id) => {
    const confirm = window.confirm('정말 삭제하시겠습니까?');
    if (confirm) {
      try {
        await DeviceApi.deleteDevice(device_id);
        console.log(`디바이스 ID ${device_id}를 삭제했습니다.`);
        window.alert('삭제가 완료되었습니다. 새로고침 해주세요.');
      } catch (error) {
        console.error('삭제 실패:', error.response.data);
        window.alert('삭제를 실패하였습니다.');
      }
    }
  };

  const handleSaveClick = async (device_id, device_ssaid) => {
    const confirm = window.confirm('변경사항을 저장하시겠습니까?');
    if (confirm) {
      try {
        const deviceInfo = {
          device_id: device_id,
          device_nm: editedDevices[device_id].device_nm,
          device_subname: editedDevices[device_id].device_subname,
          device_grade: editedDevices[device_id].device_grade,
          device_ssaid: device_ssaid,
        };
        await DeviceApi.updateDevice(deviceInfo);
        setEditedDevices((prev) => ({
          ...prev,
          [device_id]: undefined,
        }));
        setEditingDeviceId(null);
        window.alert('수정 완료!');
      } catch (error) {
        console.error('수정 실패:', error.response.data);
      }
    }
    setEditedDevices((prev) => ({
      ...prev,
      [device_id]: undefined,
    }));
    setEditingDeviceId(null);
  };

  const deviceRender = devices.map((item) => {
    const { device_id, device_nm, device_subname, device_grade, device_ssaid } =
      item;
    const isEditing = editingDeviceId === device_id;
    const editedDevice = editedDevices[device_id] || item;

    return (
      <div className='device-item' key={device_id}>
        <div className='device-subname'>
          {isEditing ? (
            <input
              type='text'
              value={editedDevice.device_subname}
              onChange={(e) =>
                setEditedDevices({
                  ...editedDevices,
                  [device_id]: {
                    ...editedDevice,
                    device_subname: e.target.value,
                  },
                })
              }
            />
          ) : (
            `좌석 이름 : ${device_subname}`
          )}
        </div>
        <div className='device-nm'>
          {isEditing ? (
            <input
              type='text'
              value={editedDevice.device_nm}
              onChange={(e) =>
                setEditedDevices({
                  ...editedDevices,
                  [device_id]: {
                    ...editedDevice,
                    device_nm: e.target.value,
                  },
                })
              }
            />
          ) : (
            `좌석 번호(기기 이름) : ${device_nm}`
          )}
        </div>{' '}
        <div className='device-edit-button'>
          {isEditing ? (
            <div>
              <input
                type='button'
                value='저장'
                className='device-save-button'
                onClick={() => handleSaveClick(device_id, device_ssaid)}
              />
            </div>
          ) : (
            <input
              type='button'
              value='수정'
              className='device-edit-button'
              onClick={() => setEditingDeviceId(device_id)}
            />
          )}
        </div>
        <div className='device-delete'>
          <input
            type='button'
            value='삭제'
            className='device-delete-button'
            onClick={() => handleDeleteClick(device_id)}
          />
        </div>
        <div className='device-grade'>
          {isEditing ? (
            <input
              type='text'
              value={editedDevice.device_grade}
              onChange={(e) =>
                setEditedDevices({
                  ...editedDevices,
                  [device_id]: {
                    ...editedDevice,
                    device_grade: e.target.value,
                  },
                })
              }
            />
          ) : (
            `좌석 설명 : ${device_grade}`
          )}
        </div>
        <div className='device-ssaid'>SSAID: {device_ssaid}</div>
      </div>
    );
  });

  return (
    <div className='device-item-container'>
      <div className='device-header' onClick={handleFold}>
        {theater_theater_nm} ({devices.length})
      </div>
      <div className='device-content' ref={ref}>
        {deviceRender}
      </div>
    </div>
  );
};

export default DeviceItem;
