import moment from 'moment';
import React, { useState } from 'react';
import { useCallback } from 'react';
import { useRef } from 'react';
import './notice-item.css';
import { NoticeApi } from 'API';
const NoticeItem = ({ notice }) => {
  const fold = useRef();
  /* Router */
  /* State */
  const { notice_title, notice_content, created_at, notice_id } = notice;
  const [isCollapse, setIsCollapse] = useState(false);
  /* Functions */
  const handleCollapse = useCallback(() => {
    if (fold.current === null) {
      return;
    }
    if (isCollapse) {
      fold.current.style.height = '0';
      fold.current.style.borderBottom = 'none';
      fold.current.style.paddingTop = '0';
    } else {
      const lines = notice_content.split('\n').length + 1;
      fold.current.style.height = `${lines * 30}px`;
      fold.current.style.borderBottom = '1px solid lightgray';
      fold.current.style.paddingTop = '10px';
    }
    setIsCollapse(!isCollapse);
  }, [isCollapse, notice_content]);
  const handleDeleteClick = async () => {
    const confirm = window.confirm('정말 삭제하시겠습니까?');

    if (confirm) {
      try {
        // console.log('show_id', show_id);
        await NoticeApi.deleteNotice(notice_id);
        window.alert('삭제가 완료되었습니다. 새로고침 해주세요.');
      } catch (error) {
        console.error('삭제 실패:', error.response.data);
        window.alert('삭제를 실패하였습니다.');
      }
    }
  };
  /* Hooks */

  /* Render */
  return (
    <div className='notice-item-container'>
      <div className='notice-header'>
        <span onClick={handleCollapse}>{notice_title}</span>
        <input
          type='button'
          value='삭제'
          className='notice-delete-button'
          onClick={() => handleDeleteClick()}
        />
        <div className='notice-time'>
          {moment(created_at).format('YYYY-MM-DD hh:mm')}
        </div>
      </div>
      <div className='notice-content' ref={fold}>
        {notice_content}
      </div>
    </div>
  );
};

export default NoticeItem;
