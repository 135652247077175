import { Route, Routes } from 'react-router-dom';
import { LoginLayout, MainLayout, Temp } from '../Components';
import {
  Audience,
  Device,
  // Dashboard,
  ENotice,
  Home,
  InitialAdmin,
  NotFound,
  Notice,
  NoticeRegister,
  Show,
  ShowRegister,
  Theater,
  TheaterRegister,
  Payment,
  Search,
} from './pages';

const MainRouter = () => {
  /* Router */
  /* State */
  /* Functions */
  /* Hooks */
  /* Render */
  return (
    <Routes>
      <Route path='/' element={<LoginLayout />}>
        <Route index element={<Home />} />
      </Route>
      <Route element={<MainLayout />}>
        <Route path='/dashboard' element={<Temp />} />
        <Route path='/theater'>
          <Route path='register' element={<TheaterRegister />} />
          <Route path='list' element={<Theater />} />
        </Route>
        <Route path='/show'>
          <Route path='register' element={<ShowRegister />} />
          <Route path='list' element={<Show />} />
        </Route>
        <Route path='/device'>
          {/* <Route path="register" element={<DeviceRegister />} /> */}
          <Route path='list' element={<Device />} />
        </Route>
        <Route path='/audience'>
          {/* <Route path="register" element={<Temp />} /> */}
          <Route path='list' element={<Audience />} />
        </Route>
        <Route path='/payment'>
          <Route path='search' element={<Search />} />
          <Route path='list' element={<Payment />} />
        </Route>
        <Route path='/notice'>
          <Route path='register' element={<NoticeRegister />} />
          <Route path='list' element={<Notice />} />
        </Route>
      </Route>
      <Route path='/external/notice' element={<ENotice />} />
      <Route path='/initial/admin' element={<InitialAdmin />} />
      <Route path='*' element={<NotFound />} />
    </Routes>
  );
};

export default MainRouter;
