import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { BUCKET_URL } from 'Utils';
import './image-upload.css';

const ImageUpload = ({ upload, preview }) => {
  /* Router */
  /* State */
  /* Functions */
  const onDrop = useCallback(
    async (acceptedFiles) => {
      // Do something with the files
      await upload(acceptedFiles[0]);
    },
    [upload]
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  /* Hooks */
  /* Render */
  return (
    <div className="image-upload-container" {...getRootProps()}>
      <input className="image-uploader" {...getInputProps()} />
      {preview ? (
        <img src={`${BUCKET_URL}/${preview}`} alt="preview" />
      ) : isDragActive ? (
        <p>이미지 업로드...</p>
      ) : (
        <p>클릭하거나 사진을 끌어주세요.</p>
      )}
    </div>
  );
};

export default ImageUpload;
