import { AudienceApi, ShowApi } from 'API';
import React, { useCallback, useEffect, useState } from 'react';
import AudiencePresenter from './AudiencePresenter';

const AudienceContainer = () => {
  /* Router */
  /* State */
  const [audienceList, setAudienceList] = useState([]);
  const [showList, setShowList] = useState([]);
  /* Functions */
  /**
   * 관중 정보 조회
   * --
   */
  const handleAudienceList = useCallback(async () => {
    const result = await AudienceApi.getAudienceList();
    if (result) {
      setAudienceList(result);
      return true;
    }
    return false;
  }, []);

  /**
   * 공연 정보 조회
   * --
   */
  const handleShowList = useCallback(async () => {
    const result = await ShowApi.getShowList();
    if (result) {
      setShowList(result);
      return true;
    }
    return false;
  }, []);

  /* Hooks */
  useEffect(() => {
    handleAudienceList();
  }, [handleAudienceList]);

  useEffect(() => {
    handleShowList();
  }, [handleShowList]);

  /* Render */
  return <AudiencePresenter audienceList={audienceList} showList={showList} />;
};

export default AudienceContainer;
