import { ApiManager } from '../../Utils';
import ApiConstant from '../ApiConstant';

const $http = new ApiManager();

const TheaterApi = {
  /**
   * 공연장 정보 등록
   * --
   * @param {*} theaterInfo
   * @returns
   */
  createTheater: async (theaterInfo) => {
    try {
      const url = ApiConstant.CREATE_THEATER;
      const { status, data, message } = await $http.post(url, theaterInfo);
      if (status === 200) {
        return data;
      }
      throw message;
    } catch (error) {
      return false;
    }
  },
  /**
   * 공연장 정보 조회
   * --
   * @returns
   */
  getTheaterList: async () => {
    try {
      const url = ApiConstant.GET_THEATER_LIST;
      const { status, data, message } = await $http.get(url);
      if (status === 200) {
        return data;
      }
      throw message;
    } catch (error) {
      return false;
    }
  },
  /**
   * 공연장 정보 수정
   * --
   * @param {*} theaterInfo
   * @returns
   */
  updateTheater: async (theaterInfo) => {
    try {
      const url = ApiConstant.UPDATE_THEATER;
      const { status, data, message } = await $http.put(url, theaterInfo);
      if (status === 200) {
        return data;
      }
      throw message;
    } catch (error) {
      return false;
    }
  },
  /**
   * 공연장 정보 삭제
   * --
   * @param {*} theater_id
   * @returns
   */
  deleteTheater: async (theater_id) => {
    try {
      const url = ApiConstant.DELETE_THEATER.replace(':theater_id', theater_id);
      const { status, data, message } = await $http.delete(url);
      if (status === 200) {
        return data;
      }
      throw message;
    } catch (error) {
      return false;
    }
  },
};

export default TheaterApi;
