import React, { useState } from 'react';
import './audience-filter.css';

const AudienceFilter = ({ cond, setCond, showList }) => {
  /* Router */
  /* State */
  const [tempCond, setTempCond] = useState(cond);
  /* Functions */
  const handleTempCond = (e) => {
    setTempCond({ ...tempCond, [e.target.name]: e.target.value });
  };

  const handleShow = (e) => {
    let val = '';
    if (e.target.value !== 'ALL') {
      val = e.target.value;
    }
    setTempCond({ ...tempCond, [e.target.name]: val });
  };

  const handleCond = () => {
    setCond(tempCond);
  };
  /* Hooks */
  /* Render */
  const optionRender = showList.map((item) => {
    const { show_id, show_title } = item;
    return (
      <option key={show_id} value={show_id}>
        {show_title}
      </option>
    );
  });
  return (
    <div className='audience-filter-container'>
      <div className='audience-filter-item'>검색 옵션</div>
      <div className='audience-filter-item'>
        <label>날짜: </label>
        <input
          type='date'
          name='date'
          value={tempCond.date}
          onChange={handleTempCond}
        />
      </div>
      <div className='audience-filter-item'>
        <label>공연: </label>
        <select name='show' id='' value={tempCond.show} onChange={handleShow}>
          <option value='ALL'>전체</option>
          {optionRender}
        </select>
      </div>
      <div className='audience-filter-item'>
        <label>관중 이름: </label>
        <input
          type='text'
          name='name'
          placeholder='관중 이름'
          value={tempCond.name}
          onChange={handleTempCond}
        />
      </div>
      <div className='audience-filter-item'>
        <button onClick={handleCond}>검색</button>
      </div>
    </div>
  );
};

export default AudienceFilter;
