import React from 'react';
import './home.css';
import LOGO from 'Assets/logo.png';
import { useState } from 'react';
const HomePresneter = ({ login }) => {
  /* Router */
  /* State */
  const initialState = {
    admin_login_id: '',
    admin_login_pw: '',
  };
  const [loginInfo, setLoginInfo] = useState(initialState);
  /* Functions */
  /* Hooks */
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(loginInfo);
    const result = await login(loginInfo);
    if (result) {
      setLoginInfo(initialState);
      return true;
    }
    return false;
  };

  const handleLoginInfo = (e) => {
    setLoginInfo({ ...loginInfo, [e.target.name]: e.target.value });
  };
  /* Render */
  return (
    <div className="home-container">
      <div className="logo">
        <img src={LOGO} alt="logo" />
      </div>
      <form className="login-form" onSubmit={handleSubmit}>
        <div className="input-form">
          <input
            type="text"
            className="input-text"
            name="admin_login_id"
            value={loginInfo.admin_login_id}
            onChange={handleLoginInfo}
            placeholder="아이디"
          />
        </div>
        <div className="input-form">
          <input
            type="password"
            className="input-text"
            placeholder="비밀번호"
            name="admin_login_pw"
            value={loginInfo.admin_login_pw}
            onChange={handleLoginInfo}
          />
        </div>
        <div className="input-form">
          <button className="input-btn">로그인</button>
        </div>
      </form>
    </div>
  );
};

export default HomePresneter;
