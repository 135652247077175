import React, { useState } from 'react';
import './initial-admin.css';

const InitialAdminPresenter = ({ signup }) => {
  /* Router */
  /* State */
  const initialState = {
    admin_login_id: '',
    admin_login_pw: '',
    admin_nm: '',
    admin_div: 'ADMIN',
  };
  const [adminInfo, setAdminInfo] = useState(initialState);
  /* Functions */
  /**
   * 관리자 정보 입력 핸들러
   * --
   * @param {*} e
   */
  const handleAdminInfo = (e) => {
    setAdminInfo({ ...adminInfo, [e.target.name]: e.target.value });
  };

  /**
   * 관리자 등록 핸들러
   * --
   * @param {*} e
   * @returns
   */
  const handleSubmit = async (e) => {
    e.preventDefault();
    const result = await signup(adminInfo);
    if (result) {
      setAdminInfo(initialState);
      return true;
    }
    return false;
  };
  /* Hooks */
  /* Render */
  return (
    <div className="initial-admin-container">
      <form className="initial-form" onSubmit={handleSubmit}>
        <div className="input-form">
          <label htmlFor="">관리자 아이디: </label>
          <input
            type="text"
            name="admin_login_id"
            value={adminInfo.admin_login_id}
            onChange={handleAdminInfo}
          />
        </div>
        <div className="input-form">
          <label htmlFor="">관리자 비밀번호: </label>
          <input
            type="text"
            name="admin_login_pw"
            value={adminInfo.admin_login_pw}
            onChange={handleAdminInfo}
          />
        </div>
        <div className="input-form">
          <label htmlFor="">관리자 이름: </label>
          <input
            type="text"
            name="admin_nm"
            value={adminInfo.admin_nm}
            onChange={handleAdminInfo}
          />
        </div>
        <div className="input-form">
          <label htmlFor="">관리자 아이디: </label>
          <select value={adminInfo.admin_div} disabled>
            <option value="ADMIN">관리자</option>
          </select>
        </div>
        <div className="input-form">
          <input type="submit" value="등록" />
        </div>
      </form>
    </div>
  );
};

export default InitialAdminPresenter;
