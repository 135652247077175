import { PageTitle } from 'Components';
import React from 'react';
import { TheaterItem } from './components';
import './theater.css';

const TheaterPresenter = ({ theaterList }) => {
  /* Router */
  /* State */
  /* Functions */
  /* Hooks */
  /* Render */
  const theaterRender = theaterList.map((item) => {
    const { theater_id } = item;
    return <TheaterItem key={theater_id} theater={item} />;
  });
  return (
    <div className='theater-container'>
      <PageTitle title='공연장 목록' />
      {theaterList.length === 0 && (
        <div className='empty'>목록이 없습니다.</div>
      )}
      {theaterRender}
    </div>
  );
};

export default TheaterPresenter;
