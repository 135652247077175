import moment, { duration } from 'moment';
// import React from 'react';
import React, { useState } from 'react';
import './audience-item.css';
import { AudienceApi } from 'API';

const AudenceItem = ({ audience }) => {
  /* Router */
  /* State */
  const [isEditing, setIsEditing] = useState(false);
  const [editedAudience, setEditedAudience] = useState({
    audience_nm: audience.audience_nm,
    audience_tel: audience.audience_tel,
  });
  const {
    audience_nm,
    show_title,
    show_start_date,
    audience_tel,
    audience_id,
  } = audience;
  // console.log(audience);
  let startDate = new Date(show_start_date);
  startDate.setHours(startDate.getHours() + 9);
  const show_date_start = startDate.toISOString();
  const start_year = show_date_start.split('T')[0];
  const start_time = show_date_start.split('T')[1].slice(0, 5);
  /* Functions */
  const saveEditedAudience = async () => {
    const confirm = window.confirm('변경된 사항을 저장하시겠습니까?');
    if (confirm) {
      try {
        const data = {
          audience_id: audience_id,
          ...editedAudience,
        };
        await AudienceApi.updateAudience(data);
        window.alert('저장이 완료되었습니다. 새로고침 해주세요.');
      } catch (error) {
        console.error('Error saving edited audience:', error);
        window.alert('저장을 실패하였습니다.');
        // Handle error
      }
    }
  };

  /* Hooks */
  /* Render */
  return (
    <div className='audience-item-container'>
      <div className='audience-header'>
        <div className='audience-date'>
          {start_year} {start_time}
        </div>
        <div className='show-title'>공연 명 : {show_title}</div>
        <div className='audience-item-update'>
          <div className='audience-nm'>
            {isEditing ? (
              <>
                <input
                  type='text'
                  value={editedAudience.audience_nm}
                  onChange={(e) =>
                    setEditedAudience({
                      ...editedAudience,
                      audience_nm: e.target.value,
                    })
                  }
                />
                <input
                  type='text'
                  value={editedAudience.audience_tel}
                  onChange={(e) =>
                    setEditedAudience({
                      ...editedAudience,
                      audience_tel: e.target.value,
                    })
                  }
                />
              </>
            ) : (
              `관객 명 : ${editedAudience.audience_nm}, 전화번호 : ${editedAudience.audience_tel}`
            )}
          </div>
          <div className='audience-edit-button'>
            {isEditing ? (
              <input
                type='button'
                value='저장'
                onClick={() => {
                  saveEditedAudience();
                  // TODO: Save editedAudience to the server
                  setIsEditing(false);
                }}
              />
            ) : (
              <input
                type='button'
                value='수정'
                onClick={() => setIsEditing(true)}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
  // return (
  //   <div className='audience-item-container'>
  //     <div className='audience-header'>
  //       <div className='audience-date'>
  //         {start_year} {start_time}
  //       </div>
  //       <div className='show-title'>{show_title}</div>
  //       <div className='audience-nm'>
  //         관객 명 : {audience_nm}, 전화번호 : {audience_tel}
  //       </div>
  //     </div>
  //   </div>
  // );
};

export default AudenceItem;
