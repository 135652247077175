import { PaymentApi } from 'API';
import React, { useCallback, useState } from 'react';
import SearchPresenter from './SearchPresenter';

const SearchContainer = () => {
  /* Router */
  /* State */
  const [SearchpaymentList, setSearchPaymentList] = useState();
  /* Functions */
  const handleSearchPaymentList = useCallback(async (keyword) => {
    const result = await PaymentApi.searchPayment(keyword);
    if (result) {
      setSearchPaymentList(result);
    }
    return false;
  }, []);

  /* Hooks */
  /* Render */
  return (
    <SearchPresenter
      paymentList={SearchpaymentList}
      search={handleSearchPaymentList}
    />
  );
};

export default SearchContainer;
