import { ApiManager } from '../../Utils';
import ApiConstant from '../ApiConstant';

const $http = new ApiManager();

const FileApi = {
  /**
   * 파일 업로드
   * --
   * @param {*} fileInfo
   * @returns
   */
  uploadFile: async (fileInfo) => {
    try {
      const url = ApiConstant.UPLOAD_FILE;
      const { status, data, message } = await $http.multipart(url, fileInfo);
      if (status === 200) {
        return data;
      }
      throw message;
    } catch (error) {
      return false;
    }
  },
};

export default FileApi;
