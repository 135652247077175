import React from 'react';
import { Outlet } from 'react-router-dom';
import VISUAL from 'Assets/visual.jpeg';
import './loginlayout.css';

const LoginLayout = () => {
  /* Router */
  /* State */
  /* Functions */
  /* Hooks */
  /* Render */
  return (
    <div className="login-container">
      <div className="visual">
        <img src={VISUAL} alt="visual" />
      </div>
      <div className="content">
        <Outlet />
      </div>
    </div>
  );
};

export default LoginLayout;
