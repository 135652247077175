import { NoticeApi } from 'API';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import NoticeRegisterPresenter from './NoticeRegisterPresenter';

const NoticeRegisterContainer = () => {
  /* Router */
  const navigate = useNavigate();
  /* State */
  /* Functions */
  /**
   * 공지사항 등록 요청
   * --
   * @param {*} noticeInfo
   * @returns
   */
  const handleCreateNotice = async (noticeInfo) => {
    const result = await NoticeApi.createNotice(noticeInfo);
    if (result) {
      navigate('/notice/list');
      return true;
    }
    return false;
  };
  /* Hooks */
  /* Render */
  return <NoticeRegisterPresenter createNotice={handleCreateNotice} />;
};

export default NoticeRegisterContainer;
