import { PageTitle } from 'Components';
import React from 'react';
import { PaymentItem } from './components'; // 수정된 부분
import './payment.css';

const PaymentPresenter = ({ paymentList }) => {
  /* Router */
  /* State */
  /* Functions */
  /* Hooks */
  /* Render */
  // console.log(paymentList);
  const paymentRender = paymentList.map((item) => {
    const { payment_id } = item;
    return <PaymentItem key={payment_id} payment={item} />; // 수정된 부분
  });
  return (
    <div className='theater-container'>
      <PageTitle title='정산 목록' />
      {paymentList.length === 0 && (
        <div className='empty'>목록이 없습니다.</div>
      )}
      {paymentRender}
    </div>
  );
};

export default PaymentPresenter;
