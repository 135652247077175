import { ImageUpload, PageTitle } from 'Components';
import React, { useEffect, useState } from 'react';
import { v4 } from 'uuid';
import './show-register.css';

const ShowRegisterPresenter = ({ createShow, uploadFile, theaterList }) => {
  /* Router */
  /* State */
  const initialState = {
    theater_id: '',
    show_title: '',
    show_desc: '',
    show_start_date: '',
    show_end_date: '',
    show_thumbnail: '',
    show_price: '',
    prepayment_price: '',
    show_usage: true,
  };
  const [showInfo, setShowInfo] = useState(initialState);
  const [preview, setPreview] = useState();
  /* Functions */
  /**
   * 공연 정보 핸들러
   * --
   * @param {*} e
   */
  const handleShowInfo = (e, val) => {
    if (val) {
      setShowInfo({ ...showInfo, [e.target.name]: val });
      return;
    }
    setShowInfo({ ...showInfo, [e.target.name]: e.target.value });
  };

  const handleTheaterInfo = (e) => {
    if (e.target.value === '0') {
      handleShowInfo(e, '0');
      return;
    }
    handleShowInfo(e);
  };

  /**
   * 파일 업로드 핸들러
   * --
   * @param {*} file
   * @returns
   */
  const handleUpload = async (file) => {
    const id = v4();
    const form = new FormData();
    form.append('file', file);
    form.append('file_path', `show/${id}`);
    const result = await uploadFile(form);
    if (result) {
      setPreview(result);
      return true;
    }
    return false;
  };

  /**
   * 공연 등록 핸들러
   * --
   * @param {*} e
   */
  const handleCreateShow = async (e) => {
    e.preventDefault();
    const postData = {
      ...showInfo,
      prepayment_price: Number(showInfo.prepayment_price),
      show_price: Number(showInfo.show_price),
    };
    const result = await createShow(postData);
    if (result) {
      setShowInfo(initialState);
      setPreview();
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (!preview) {
      return;
    }
    if (showInfo.show_thumbnail) {
      return;
    }
    setShowInfo({ ...showInfo, show_thumbnail: preview });
  }, [preview, showInfo]);

  /* Hooks */
  /* Render */
  const theaterRender = theaterList.map((item) => {
    const { theater_id, theater_nm } = item;
    return (
      <option key={theater_id} value={theater_id}>
        {theater_nm}
      </option>
    );
  });
  return (
    <div className="show-register-container">
      <PageTitle title="공연 등록" />
      <form onSubmit={handleCreateShow} className="show-form">
        <div className="input-form">
          <label>사진</label>
          <ImageUpload preview={preview} upload={handleUpload} />
        </div>
        <div className="input-form">
          <label htmlFor="show_theater">공연장</label>
          <select
            name="theater_id"
            id="show_theater"
            className="border"
            onChange={handleTheaterInfo}
          >
            <option value="0">공연장 선택</option>
            {theaterRender}
          </select>
        </div>
        <div className="input-form">
          <label htmlFor="show_title">공연명</label>
          <input
            type="text"
            name="show_title"
            id="show_title"
            value={showInfo.show_title}
            onChange={handleShowInfo}
            className="border"
            placeholder="공연명"
          />
        </div>
        <div className="input-form">
          <label htmlFor="show_date">공연일정</label>
          <input
            type="datetime-local"
            name="show_start_date"
            id="show_start_date"
            value={showInfo.show_start_date}
            onChange={handleShowInfo}
            placeholder="공연시작일자(시간)"
          />
          ~
          <input
            type="datetime-local"
            name="show_end_date"
            id="show_end_date"
            value={showInfo.show_end_date}
            onChange={handleShowInfo}
            placeholder="공연종료일자(시간)"
          />
        </div>
        <div className="input-form">
          <label htmlFor="show_desc">공연설명</label>
          <textarea
            name="show_desc"
            id="show_desc"
            cols="30"
            rows="15"
            value={showInfo.show_desc}
            onChange={handleShowInfo}
            placeholder="공연설명"
          />
        </div>
        <div className="input-form">
          <label htmlFor="prepayment_price">사전 결제 금액</label>
          <input
            type="text"
            name="prepayment_price"
            id="prepayment_price"
            className="border"
            value={showInfo.prepayment_price}
            onChange={handleShowInfo}
            placeholder="공연 사전 결제 금액"
          />
        </div>
        <div className="input-form">
          <label htmlFor="show_price">웃음가격</label>
          <input
            type="text"
            name="show_price"
            id="show_price"
            className="border"
            value={showInfo.show_price}
            onChange={handleShowInfo}
            placeholder="웃음가격"
          />
        </div>
        <div className="input-form">
          <input type="submit" value="등록" />
        </div>
      </form>
    </div>
  );
};

export default ShowRegisterPresenter;
