import { ShowApi } from 'API';
import React, { useCallback, useEffect, useState } from 'react';
import ShowPresenter from './ShowPresenter';

const ShowContainer = () => {
  /* Router */
  /* State */
  const [showList, setShowList] = useState([]);
  /* Functions */
  /**
   * 공연 목록 조회
   * --
   */
  const handleShowList = useCallback(async () => {
    const result = await ShowApi.getShowList();
    if (result) {
      setShowList(result);
      return true;
    }
    return false;
  }, []);
  /* Hooks */
  useEffect(() => {
    handleShowList();
  }, [handleShowList]);

  /* Render */
  return <ShowPresenter showList={showList} />;
};

export default ShowContainer;
