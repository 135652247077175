import { ApiManager } from '../../Utils';
import ApiConstant from '../ApiConstant';

const $http = new ApiManager();

const ShowApi = {
  /**
   * 공연 정보 등록
   * --
   * @param {*} showInfo
   * @returns
   */
  createShow: async (showInfo) => {
    try {
      const url = ApiConstant.CREATE_SHOW;
      const { status, data, message } = await $http.post(url, showInfo);
      if (status === 200) {
        return data;
      }
      throw message;
    } catch (error) {
      return false;
    }
  },
  /**
   * 공연 정보 목록 조회
   * --
   * @returns
   */
  getShowList: async () => {
    try {
      const url = ApiConstant.GET_SHOW_LIST;
      const { status, data, message } = await $http.get(url);
      if (status === 200) {
        return data;
      }
      throw message;
    } catch (error) {
      return false;
    }
  },
  /**
   * 공연 정보 상세 조회
   * --
   * @param {*} show_id
   * @returns
   */
  getShow: async (show_id) => {
    try {
      const url = ApiConstant.GET_SHOW.replace(':show_id', show_id);
      const { status, data, message } = await $http.get(url);
      if (status === 200) {
        return data;
      }
      throw message;
    } catch (error) {
      return false;
    }
  },
  /**
   * 공연 정보 수정
   * --
   * @param {*} showInfo
   * @returns
   */
  updateShow: async (showInfo) => {
    try {
      const url = ApiConstant.UPDATE_SHOW;
      const { status, data, message } = await $http.put(url, showInfo);
      if (status === 200) {
        return data;
      }
      throw message;
    } catch (error) {
      return false;
    }
  },
  /**
   * 공연 정보 삭제
   * --
   * @param {*} show_id
   * @returns
   */
  deleteShow: async (show_id) => {
    try {
      const url = ApiConstant.DELETE_SHOW.replace(':show_id', show_id);
      const { status, data, message } = await $http.delete(url);
      if (status === 200) {
        return data;
      }
      throw message;
    } catch (error) {
      return false;
    }
  },
};

export default ShowApi;
