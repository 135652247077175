import { FileApi, TheaterApi } from 'API';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import TheaterRegisterPresenter from './TheaterRegisterPresenter';

const TheaterRegisterContainer = () => {
  /* Router */
  const navigate = useNavigate();
  /* State */
  /* Functions */
  /**
   * 공연장 등록
   * --
   * @param {*} theaterInfo
   * @returns
   */
  const handleCreateTheater = async (theaterInfo) => {
    const result = await TheaterApi.createTheater(theaterInfo);
    if (result) {
      navigate('/theater/list');
      return true;
    }
    return false;
  };

  /**
   * 파일 업로드
   * --
   * @param {*} uploadInfo
   * @returns
   */
  const handleUpload = async (uploadInfo) => {
    const result = await FileApi.uploadFile(uploadInfo);
    if (result) {
      return result;
    }
    return false;
  };
  /* Hooks */
  /* Render */
  return (
    <TheaterRegisterPresenter
      createTheater={handleCreateTheater}
      uploadFile={handleUpload}
    />
  );
};

export default TheaterRegisterContainer;
