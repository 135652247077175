import { TheaterApi } from 'API';
import React, { useCallback, useEffect, useState } from 'react';
import TheaterPresenter from './TheaterPresenter';

const TheaterContainer = () => {
  /* Router */
  /* State */
  const [theaterList, setTheaterList] = useState([]);
  /* Functions */
  const handleTheaterList = useCallback(async () => {
    const result = await TheaterApi.getTheaterList();
    // console.log('result', result);
    if (result) {
      setTheaterList(result);
      return true;
    }
    return false;
  }, []);
  /* Hooks */
  useEffect(() => {
    handleTheaterList();
  }, [handleTheaterList]);

  return <TheaterPresenter theaterList={theaterList} />;
};
export default TheaterContainer;
