import { PageTitle } from 'Components';
import React from 'react';
import { NoticeItem } from '../Notice/components';
import './enotice.css';

const ENoticePresenter = ({ noticeList }) => {
  /* Router */
  /* State */
  /* Functions */
  /* Hooks */
  /* Render */
  const noticeRender = noticeList.map((item) => {
    const { notice_id } = item;
    return <NoticeItem key={notice_id} notice={item} />;
  });
  return (
    <div className="enotice-container">
      <div className="enotice-item">
        <PageTitle title="공지사항" />
        {noticeList.length === 0 && (
          <div className="empty">목록이 없습니다.</div>
        )}
        {noticeRender}
      </div>
    </div>
  );
};

export default ENoticePresenter;
