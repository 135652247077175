import { ApiManager } from '../../Utils';
import ApiConstant from '../ApiConstant';

const $http = new ApiManager();

const PaymentApi = {
  /**
   * 정산 정보 생성
   * --
   * @param {*} paymentInfo
   * @returns
   */
  creaetPayment: async (paymentInfo) => {
    try {
      const url = ApiConstant.CREATE_PAYMENT;
      const { status, data, message } = await $http.post(url, paymentInfo);
      if (status === 200) {
        return data;
      }
      throw message;
    } catch (error) {
      return false;
    }
  },
  /**
   * 정산 목록 조회
   * --
   * @returns
   */
  getPaymentList: async () => {
    try {
      const url = ApiConstant.GET_PAYMENT_LIST;
      const { status, data, message } = await $http.get(url);
      if (status === 200) {
        return data;
      }
      throw message;
    } catch (error) {
      return false;
    }
  },
  /**
   * 정산 상세 정보 조회
   * --
   * @param {*} payment_id
   * @returns
   */
  getPayment: async (payment_id) => {
    try {
      const url = ApiConstant.GET_PAYMENT.replace(':payment_id', payment_id);
      const { status, data, message } = await $http.get(url);
      if (status === 200) {
        return data;
      }
      throw message;
    } catch (error) {
      return false;
    }
  },
  /**
   * 정산 수정
   * --
   * @param {*} payment_id
   * @returns
   */
  updatePayment: async (paymentinfo) => {
    try {
      const url = ApiConstant.UPDATE_PAYMENT;
      const { status, data, message } = await $http.put(url, paymentinfo);
      if (status === 200) {
        return data;
      }
      throw message;
    } catch (error) {
      return false;
    }
  },
  /**
   * 정산 삭제
   * --
   * @param {*} payment_id
   * @returns
   */
  deletePayment: async (payment_id) => {
    try {
      console.log('payment_idsadsa', payment_id);
      const url = ApiConstant.DELETE_PAYMENT.replace(':payment_id', payment_id);
      const { status, data, message } = await $http.delete(url);
      if (status === 200) {
        return data;
      }
      throw message;
    } catch (error) {
      return false;
    }
  },
  /**
   * 정산 검색
   * --
   * @param {*} keyword
   * @returns
   */
  searchPayment: async (keyword) => {
    try {
      const url = ApiConstant.SEARCH_PAYMENT.replace(':keyword', keyword);
      const { status, data, message } = await $http.get(url);
      if (status === 200) {
        return data;
      }
      throw message;
    } catch (error) {
      console.log(error);
      return false;
    }
  },
};

export default PaymentApi;
