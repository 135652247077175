import { ApiManager } from '../../Utils';
import ApiConstant from '../ApiConstant';

const $http = new ApiManager();

const AudienceApi = {
  /**
   * 관중 등록
   * --
   * @param {*} audienceInfo
   * @returns
   */
  createAudience: async (audienceInfo) => {
    try {
      const url = ApiConstant.CREATE_AUDIENCE;
      const { status, data, message } = await $http.post(url, audienceInfo);
      if (status === 200) {
        return data;
      }
      throw message;
    } catch (error) {
      return false;
    }
  },
  /**
   * 관중 목록 조회
   * --
   * @returns
   */
  getAudienceList: async () => {
    try {
      const url = ApiConstant.GET_AUDIENCE_LIST;
      const { status, data, message } = await $http.get(url);
      if (status === 200) {
        return data;
      }
      throw message;
    } catch (error) {
      return false;
    }
  },
  /**
   * 관중 상세 조회
   * --
   * @param {*} audience_id
   * @returns
   */
  getAudience: async (audience_id) => {
    try {
      const url = ApiConstant.GET_AUDIENCE.replace(':audience_id', audience_id);
      const { status, data, message } = await $http.get(url);
      if (status === 200) {
        return data;
      }
      throw message;
    } catch (error) {
      return false;
    }
  },
  /**
   * 공연별 관중 정보 조회
   * --
   * @param {*} show_id
   * @returns
   */
  getAudienceByShow: async (show_id) => {
    try {
      const url = ApiConstant.GET_AUDIENCE_BY_SHOW.replace(':show_id', show_id);
      const { status, data, message } = await $http.get(url);
      if (status === 200) {
        return data;
      }
      throw message;
    } catch (error) {
      return false;
    }
  },
  /**
   * 관중 정보 수정
   * --
   * @param {*} audienceInfo
   * @returns
   */
  updateAudience: async (audienceInfo) => {
    try {
      const url = ApiConstant.UPDATE_AUDIENCE;
      const { status, data, message } = await $http.put(url, audienceInfo);
      if (status === 200) {
        return data;
      }
      throw message;
    } catch (error) {
      return false;
    }
  },
  /**
   * 관중 정보 삭제
   * --
   * @param {*} audience_id
   * @returns
   */
  deleteAudience: async (audience_id) => {
    try {
      const url = ApiConstant.DELETE_AUDIENCE.replace(
        ':audience_id',
        audience_id
      );
      const { status, data, message } = await $http.delete(url);
      if (status === 200) {
        return data;
      }
      throw message;
    } catch (error) {
      return false;
    }
  },
};

export default AudienceApi;
