import { ApiManager } from '../../Utils';
import ApiConstant from '../ApiConstant';

const $http = new ApiManager();

const NoticeApi = {
  /**
   * 공지사항 등록
   * --
   * @param {*} noticeInfo
   * @returns
   */
  createNotice: async (noticeInfo) => {
    try {
      const url = ApiConstant.CREATE_NOTICE;
      const { status, data, message } = await $http.post(url, noticeInfo);
      if (status === 200) {
        return data;
      }
      throw message;
    } catch (error) {
      return false;
    }
  },
  /**
   * 공지사항 정보 조회
   * --
   * @returns
   */
  getNoticeList: async () => {
    try {
      const url = ApiConstant.GET_NOTICE_LIST;
      const { status, data, message } = await $http.get(url);
      if (status === 200) {
        return data;
      }
      throw message;
    } catch (error) {
      return false;
    }
  },
  /**
   * 공지사항 정보 수정
   * --
   * @param {*} noticeInfo
   * @returns
   */
  updateNotice: async (noticeInfo) => {
    try {
      const url = ApiConstant.UPDATE_NOTICE;
      const { status, data, message } = await $http.put(url, noticeInfo);
      if (status === 200) {
        return data;
      }
      throw message;
    } catch (error) {
      return false;
    }
  },
  /**
   * 공지사항 정보 삭제
   * --
   * @param {*} notice_id
   * @returns
   */
  deleteNotice: async (notice_id) => {
    try {
      const url = ApiConstant.DELETE_NOTICE.replace(':notice_id', notice_id);
      const { status, data, message } = await $http.delete(url);
      if (status === 200) {
        return data;
      }
      throw message;
    } catch (error) {
      return false;
    }
  },
};

export default NoticeApi;
