import React, { useState, useCallback, useRef } from 'react';
import { TheaterApi } from 'API';
import { BUCKET_URL } from 'Utils';
import LOGO from 'Assets/logo.png';
import './theateritem.css';

const TheaterItem = ({ theater }) => {
  const ref = useRef();
  /* Router */
  /* State */
  const [data, setData] = useState(theater);
  const [isCollapse, setIsCollapse] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  /* Functions */
  const handleFold = useCallback(() => {
    if (!ref.current) {
      return;
    }

    if (isCollapse) {
      ref.current.style.height = 0;
      ref.current.style.borderBottom = 'none';
    } else {
      const d = data?.theater_desc.split('\n').length;
      ref.current.style.height = `${d * 26}px`;
      ref.current.style.borderBottom = '1px solid #c4c4c4';
    }
    setIsCollapse(!isCollapse);
  }, [isCollapse, data?.theater_desc]);

  /**
   * 데이터 바인딩 함수
   * --
   */
  const handleChange = (t, v, callback = null) => {
    let newData = { ...data };

    newData[t] = v;
    const d = {
      ...newData,
    };
    setData(d);

    if (callback) callback(v);
  };

  const handleDeleteClick = async () => {
    const confirm = window.confirm('정말 삭제하시겠습니까?');

    if (confirm) {
      try {
        // console.log('theater_id', theater_id);
        await TheaterApi.deleteTheater(data?.theater_id);
        window.alert('삭제가 완료되었습니다. 새로고침 해주세요.');
      } catch (error) {
        console.error('삭제 실패:', error.response.data);
        window.alert('삭제를 실패했습니다.');
      }
    }
  };
  const handleSaveClick = async () => {
    const confirm = window.confirm('변경사항을 저장하시겠습니까?');
    if (confirm) {
      const { theater_nm, theater_max, theater_min, theater_desc, theater_id } =
        data;
      try {
        await TheaterApi.updateTheater({
          theater_nm,
          theater_max: parseInt(theater_max),
          theater_min: parseInt(theater_min),
          theater_id,
        });
        window.alert('수정이 완료되었습니다.');
      } catch (error) {
        console.error('수정 실패:', error.response.data);
        window.alert('수정을 실패헀습니다.');
      }
    }
    setIsEditing(false);
  };

  /* Hooks */
  /* Render */
  return (
    <div className='theater-item-container'>
      <div className='theater-header'>
        <div className='theater-thumbnail'>
          {data?.theater_thumbnail ? (
            <img
              src={`${BUCKET_URL}/${data?.theater_thumbnail}`}
              alt={data?.theater_id}
            />
          ) : (
            <img src={LOGO} alt={data?.theater_id} />
          )}
        </div>
        <div className='theater-title' onClick={handleFold}>
          {isEditing ? (
            <>
              <div className='theater-nm'>
                <input
                  type='text'
                  value={data?.theater_nm}
                  onChange={(e) => handleChange('theater_nm', e.target.value)}
                />
              </div>
              <div className='theater-people'>
                <input
                  type='text'
                  value={data?.theater_min}
                  onChange={(e) => handleChange('theater_min', e.target.value)}
                />
                명 ~{' '}
                <input
                  type='text'
                  value={data?.theater_max}
                  onChange={(e) => handleChange('theater_max', e.target.value)}
                />
                명
              </div>
            </>
          ) : (
            <>
              <div className='theater-nm'>{data?.theater_nm}</div>
              <div className='theater-people'>
                {data?.theater_min}명 ~ {data?.theater_max}명
              </div>
            </>
          )}
        </div>
        {isEditing ? (
          <input
            type='button'
            value='저장'
            className='theater-button'
            onClick={() => handleSaveClick()}
          />
        ) : (
          <input
            type='button'
            value='수정'
            className='theater-button'
            onClick={() => setIsEditing(!isEditing)}
          />
        )}
        <input
          type='button'
          value='삭제'
          className='theater-button del'
          onClick={handleDeleteClick}
        />
      </div>
      <div className='theater-content' ref={ref}>
        {data?.theater_desc}
      </div>
    </div>
  );
};

export default TheaterItem;
