import React, { useEffect } from 'react';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import { deleteCookie, getCookie, readBuffer } from 'Utils';
import LOGO from 'Assets/logo.png';
import './mainlayout.css';
import { Accordion, Dropdown } from './components';
import { useCallback } from 'react';

const MainLayout = () => {
  /* Router */
  const navigate = useNavigate();
  /* State */
  const adminInfo = JSON.parse(getCookie('admin'));
  const admin_nm = adminInfo && readBuffer(adminInfo.admin_nm);

  const menus = [
    {
      id: 1,
      title: '공지사항 관리',
      children: [
        {
          id: '1-1',
          title: '공지사항 등록',
          path: '/notice/register',
        },
        {
          id: '1-2',
          title: '공지사항 목록',
          path: '/notice/list',
        },
      ],
    },
    {
      id: 2,
      title: '공연장 관리',
      children: [
        {
          id: '2-1',
          title: '공연장 등록',
          path: '/theater/register',
        },
        {
          id: '2-2',
          title: '공연장 목록',
          path: '/theater/list',
        },
      ],
    },
    {
      id: 3,
      title: '공연 관리',
      children: [
        {
          id: '3-1',
          title: '공연 등록',
          path: '/show/register',
        },
        {
          id: '3-2',
          title: '공연 목록',
          path: '/show/list',
        },
      ],
    },
    {
      id: 4,
      title: '기기 관리',
      children: [
        // {
        //   id: '4-1',
        //   title: '기기 등록',
        //   path: '/device/register',
        // },
        {
          id: '4-2',
          title: '기기 목록',
          path: '/device/list',
        },
      ],
    },
    {
      id: 5,
      title: '관중 관리',
      children: [
        // {
        //   id: '5-1',
        //   title: '관중 등록',
        //   path: '/audience/register',
        // },
        {
          id: '5-2',
          title: '관중 목록',
          path: '/audience/list',
        },
      ],
    },
    {
      id: 6,
      title: '정산 관리',
      children: [
        {
          id: '6-1',
          title: '정산 검색',
          path: '/payment/search',
        },
        {
          id: '6-2',
          title: '정산 목록',
          path: '/payment/list',
        },
      ],
    },
  ];
  /* Functions */
  /**
   * 세션 검증
   * --
   */
  const checkSession = useCallback(() => {
    const token = getCookie('bicf_token');
    if (token) {
      return;
    }
    navigate('/');
  }, [navigate]);

  const handleLogout = () => {
    deleteCookie('bicf_token');
    deleteCookie('admin');
    navigate('/');
  };

  /* Hooks */
  useEffect(() => {
    checkSession();
  }, [checkSession]);

  /* Render */

  const menuRender = menus.map((item) => {
    const { children, id, title } = item;
    return <Accordion key={id} title={title} menu={children} />;
  });

  return (
    <div className='main-layout-container'>
      <div className='sidebar'>
        <Link to='/' className='logo'>
          <img src={LOGO} alt='logo' />
        </Link>
        <div className='menu-box'>
          <div className='menu-title menu '>
            <Link to='/dashboard' className='menu-text'>
              대시보드
            </Link>
          </div>
        </div>
        {menuRender}
      </div>
      <div className='content'>
        <div className='status'>
          <div className='user-box'>
            <Dropdown title={admin_nm} action1={handleLogout} />
          </div>
        </div>
        <div className='outlet'>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default MainLayout;
