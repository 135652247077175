import { PageTitle } from 'Components';
import React, { useState } from 'react';
import { NoticeItem } from './components';
import './notice.css';
const NoticePresenter = ({ noticeList }) => {
  /* Router */
  /* State */
  const [active, setActive] = useState();
  /* Functions */
  const handleActive = (num) => {
    setActive(num);
  };
  /* Hooks */
  /* Render */
  const NoticeRender = noticeList.map((item) => {
    const { notice_id } = item;
    return (
      <NoticeItem
        key={notice_id}
        notice={item}
        active={handleActive}
        active_id={active}
      />
    );
  });

  return (
    <div className="notice-container">
      <PageTitle title="공지사항" />
      {noticeList.length === 0 && (
        <div className="empty">리스트가 없습니다</div>
      )}
      <div className="notice-box">{NoticeRender}</div>
    </div>
  );
};

export default NoticePresenter;
