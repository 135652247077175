import React from 'react';
import './pagetitle.css';

const PageTitle = ({ title = '페이지 타이틀' }) => {
  return <div className="page-title">{title}</div>;
};

PageTitle.defaultProps = {
  title: '페이지 타이틀',
};

export default PageTitle;
