import React from 'react';
import NOTFOUND from 'Assets/notfound.png';
import './notfound.css';
import { Link } from 'react-router-dom';

const NotFound = () => {
  /* Router */
  /* State */
  /* Functions */
  /* Hooks */
  /* Render */
  return (
    <div className="notfound-container">
      <div className="notfound-img">
        <img src={NOTFOUND} alt="notfound" />
      </div>
      <Link to="/">홈페이지로</Link>
    </div>
  );
};

export default NotFound;
