import { PageTitle } from 'Components';
import React, { useCallback, useEffect, useState } from 'react';
import './audience.css';
import { AudienceFilter, AudienceItem } from './components';

export const ORDER_TYPE = {
  ASC: 'ASC',
  DESC: 'DESC',
};

const AudiencePresenter = ({ audienceList, showList }) => {
  /* Router */
  /* State */
  const initialCondition = {
    date: '',
    show: '',
    name: '',
  };
  const [cond, setCond] = useState(initialCondition);
  const [filterList, setFilterList] = useState(audienceList);
  /* Functions */
  const handleFilterList = useCallback(() => {
    const { date, show, name } = cond;
    const temp = audienceList
      .filter((item) => item.show_start_date.includes(date))
      .filter((item) => item.show_id.includes(show))
      .filter((item) => item.audience_nm.includes(name))
      .sort((a, b) => b.show_start_date - a.show_start_date);
    setFilterList(temp);
  }, [audienceList, cond]);

  /* Hooks */
  useEffect(() => {
    handleFilterList();
  }, [cond, handleFilterList]);

  /* Render */
  const audienceRender = filterList.map((item) => {
    const { audience_id } = item;
    return <AudienceItem key={audience_id} audience={item} />;
  });
  return (
    <div className="audience-container">
      <PageTitle title="관중 목록" />
      {filterList.length === 0 && <div className="empty">목록이 없습니다.</div>}
      <AudienceFilter cond={cond} setCond={setCond} showList={showList} />
      {audienceRender}
    </div>
  );
};

export default AudiencePresenter;
