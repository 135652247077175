import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';

const Accordion = ({ title, menu }) => {
  const childRef = React.useRef(null);
  /* Router */
  /* State */
  const [isCollapse, setIsCollapse] = React.useState(false);
  /* Functions */
  const handleCollapse = useCallback(() => {
    if (childRef.current === null) {
      return;
    }
    if (isCollapse) {
      childRef.current.style.height = '0';
    } else {
      childRef.current.style.height = `${38 * menu.length}px`;
    }
    setIsCollapse(!isCollapse);
  }, [isCollapse, menu]);

  /* Hooks */
  /* Render */

  const menuItemRender = menu.map((item) => {
    const { id, path, title } = item;
    return (
      <div className="menu-item menu" key={id}>
        <Link to={path} className="menu-text">
          {title}
        </Link>
      </div>
    );
  });

  return (
    <div className="menu-box">
      <div className="menu-title menu" onClick={handleCollapse}>
        <div className="menu-text">{title}</div>
      </div>
      <div className={`menus`} ref={childRef}>
        {menuItemRender}
      </div>
    </div>
  );
};

export default Accordion;
// ${isCollapse ? 'active' : ''}
