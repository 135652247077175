import { FileApi, ShowApi, TheaterApi } from 'API';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import ShowRegisterPresenter from './ShowRegisterPresenter';

const ShowRegisterContainer = () => {
  /* Router */
  const navigate = useNavigate();
  /* State */
  const [theaterList, setTheaterList] = useState([]);
  /* Functions */
  /**
   * 파일 업로드
   * --
   * @param {*} uploadInfo
   * @returns
   */
  const handleUpload = async (uploadInfo) => {
    const result = await FileApi.uploadFile(uploadInfo);
    if (result) {
      return result;
    }
    return false;
  };

  /**
   * 공연장 정보 목록 조회
   * --
   */
  const handleTheaterList = useCallback(async () => {
    const result = await TheaterApi.getTheaterList();
    if (result) {
      setTheaterList(result);
      return true;
    }
    return false;
  }, []);

  /**
   * 공연 정보 등록
   * --
   * @param {*} showInfo
   * @returns
   */
  const handleCreateShow = async (showInfo) => {
    const result = await ShowApi.createShow(showInfo);
    if (result) {
      navigate('/show/list');
      return true;
    }
    return false;
  };
  /* Hooks */
  useEffect(() => {
    handleTheaterList();
  }, [handleTheaterList]);
  /**
   * 공연장 정보 삭제
   * --
   */
  const handleDeleteTheater = useCallback(async (theater_id) => {
    const result = await TheaterApi.deleteTheater(theater_id);
    if (result) {
      setTheaterList(result);
      return true;
    }
    return false;
  }, []);
  /* Render */
  return (
    <ShowRegisterPresenter
      theaterList={theaterList}
      createShow={handleCreateShow}
      uploadFile={handleUpload}
    />
  );
};

export default ShowRegisterContainer;
