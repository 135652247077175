import PaymentPresenter from './PaymentPresenter';
import React, { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import { PaymentApi } from 'API';
const PaymentContainer = () => {
  /* Router */
  const [paymentList, setPaymentList] = useState([]);
  const [paymentQty, setPaymentQty] = useState(0);
  /* State */
  /* Functions */
  const handlePaymentList = useCallback(async () => {
    const result = await PaymentApi.getPaymentList();
    if (result) {
      setPaymentQty(result.length);

      // Create an object to store payment status counts for each show_id
      const PaymentStatusCounts = {};

      const showList = _.uniqBy(result, 'show_id');
      const rr = showList.map((item) => {
        const temp = result.filter(
          (subItem) => subItem.show_id === item.show_id
        );

        // Calculate status counts for each show_id
        const statusCounts = temp.reduce(
          (counts, payment) => {
            if (payment.payment_status === 'SUCCESS') {
              counts.success++;
            } else if (payment.payment_status === 'PENDING') {
              counts.pending++;
            }
            return counts;
          },
          { success: 0, pending: 0 }
        );

        // Store status counts for the current show_id
        PaymentStatusCounts[item.show_title] = statusCounts;

        return {
          ...item,
          payments: temp,
          ...PaymentStatusCounts[item.show_title],
        };
      });
      setPaymentList(rr);
      return true;
    }
    return false;
  }, []);
  /* Hooks */
  useEffect(() => {
    handlePaymentList();
  }, [handlePaymentList]);
  /* Render */
  return <PaymentPresenter paymentList={paymentList} paymentQty={paymentQty} />;
};

export default PaymentContainer;
