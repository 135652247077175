import { BASE_URL } from '../Utils';

/**
 * 관리자 API
 * --
 */
const AdminApi = {
  /**
   * @method POST
   * @param
   */
  CREATE_ADMIN: `${BASE_URL}/admin`,
};

/**
 * 인증 API
 * --
 */
const AuthApi = {
  /**
   * @method POST
   * @param
   */
  SIGNUP_ADMIN: `${BASE_URL}/auth/signup`,
  /**
   * @method POST
   * @param
   */
  SIGNIN_ADMIN: `${BASE_URL}/auth/signin`,
  /**
   * @method GET
   * @param
   */
  CHECK_INITIAL: `${BASE_URL}/auth/check/initial`,
};

/**
 * 공지사항 API
 * --
 */
const NoticeApi = {
  /**
   * @method POST
   * @param
   */
  CREATE_NOTICE: `${BASE_URL}/notice`,
  /**
   * @method GET
   * @param
   */
  GET_NOTICE_LIST: `${BASE_URL}/notice`,
  /**
   * @method PUT
   * @param
   */
  UPDATE_NOTICE: `${BASE_URL}/notice`,
  /**
   * @method DELETE
   * @param { string } notice_id
   */
  DELETE_NOTICE: `${BASE_URL}/notice/:notice_id`,
};

/**
 * 공연장 API
 * --
 */
const TheaterApi = {
  /**
   * @method POST
   * @param
   */
  CREATE_THEATER: `${BASE_URL}/theater`,
  /**
   * @method GET
   * @param
   */
  GET_THEATER_LIST: `${BASE_URL}/theater`,
  /**
   * @method PUT
   * @param
   */
  UPDATE_THEATER: `${BASE_URL}/theater`,
  /**
   * @method DELETE
   * @param { string } theater_id
   */
  DELETE_THEATER: `${BASE_URL}/theater/:theater_id`,
};

/**
 * 기기 API
 * --
 */
const DeviceApi = {
  /**
   * @method POST
   * @param
   */
  CREATE_DEVICE: `${BASE_URL}/device`,
  /**
   * @method GET
   * @param
   */
  GET_DEVICE_LIST: `${BASE_URL}/device`,
  /**
   * @method GET
   * @param { string } theater_id
   */
  GET_DEVICE_BY_THEATER: `${BASE_URL}/device/theater/:theater_id`,
  /**
   * @method GET
   * @param { string } device_id
   */
  GET_DEVICE: `${BASE_URL}/device/detail/:device_id`,
  /**
   * @method PUT
   * @param
   */
  UPDATE_DEVICE: `${BASE_URL}/device`,
  /**
   * @method DELETE
   * @param { string } device_id
   */
  DELETE_DEVICE: `${BASE_URL}/device/:device_id`,
};

/**
 * 공연 API
 * --
 */
const ShowApi = {
  /**
   * @method POST
   * @param
   */
  CREATE_SHOW: `${BASE_URL}/show`,
  /**
   * @method GET
   * @param
   */
  GET_SHOW_LIST: `${BASE_URL}/show`,
  /**
   * @method GET
   * @param { string } show_id
   */
  GET_SHOW: `${BASE_URL}/show/detail/:show_id`,
  /**
   * @method PUT
   * @param
   */
  UPDATE_SHOW: `${BASE_URL}/show`,
  /**
   * @method DELETE
   * @param { string } show_id
   */
  DELETE_SHOW: `${BASE_URL}/show/:show_id`,
};

/**
 * 관중 API
 * --
 */
const AudienceApi = {
  /**
   * @method POST
   * @param
   */
  CREATE_AUDIENCE: `${BASE_URL}/audience`,
  /**
   * @method GET
   * @param
   */
  GET_AUDIENCE_LIST: `${BASE_URL}/audience`,
  /**
   * @method GET
   * @param { string } audience_id
   */
  GET_AUDIENCE: `${BASE_URL}/audience/detail/:audience_id`,
  /**
   * @method GET
   * @param { string } show_id
   */
  GET_AUDIENCE_BY_SHOW: `${BASE_URL}/audience/show/:show_id`,
  /**
   * @method PUT
   * @param
   */
  UPDATE_AUDIENCE: `${BASE_URL}/audience`,
  /**
   * @method DELETE
   * @param { string } audience_id
   */
  DELETE_AUDIENCE: `${BASE_URL}/audience/:audience_id`,
};

/**
 * 결제 API
 * --
 */
const PaymentApi = {
  /**
   * @method POST
   * @param
   */
  CREATE_PAYMENT: `${BASE_URL}/payment`,
  /**
   * @method GET
   * @param
   */
  GET_PAYMENT_LIST: `${BASE_URL}/payment`,
  /**
   * @method GET
   * @param { string } payment_id
   */
  GET_PAYMENT: `${BASE_URL}/payment/detail/:payment_id`,
  /**
   * @method GET
   * @param { string } show_id
   */
  GET_PAYMENT_BY_SHOW: `${BASE_URL}/payment/show/:show_id`,
  /**
   * @method PUT
   * @param
   */
  UPDATE_PAYMENT: `${BASE_URL}/payment`,
  /**
   * @method DELETE
   * @param { string } payment_id
   */
  DELETE_PAYMENT: `${BASE_URL}/payment`,
  /**
   * @method GET
   * @param {string} keyword
   */
  SEARCH_PAYMENT: `${BASE_URL}/search/:keyword`,
};

/**
 * 파일 API
 * --
 */
const FileApi = {
  /**
   * @method POST
   * @param
   */
  UPLOAD_FILE: `${BASE_URL}/file`,
  /**
   * @method POST
   * @param
   */
  UPLOAD_FILES: `${BASE_URL}/file/multi`,
};

const ApiConstant = {
  ...AdminApi,
  ...AuthApi,
  ...NoticeApi,
  ...TheaterApi,
  ...DeviceApi,
  ...ShowApi,
  ...AudienceApi,
  ...PaymentApi,
  ...FileApi,
};

export default ApiConstant;
