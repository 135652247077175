import { ApiManager } from '../../Utils';
import ApiConstant from '../ApiConstant';

const $http = new ApiManager();

const AdminApi = {
  /**
   * 관리자 로그인
   * --
   * @param {*} loginInfo
   * @returns
   */
  signinAdmin: async (loginInfo) => {
    try {
      const url = ApiConstant.SIGNIN_ADMIN;
      const { status, data, message } = await $http.post(url, loginInfo);
      if (status === 200) {
        return data;
      }
      throw message;
    } catch (error) {
      return false;
    }
  },
  /**
   * 관리자 회원가입 API
   * --
   * @param {*} adminInfo
   * @returns
   */
  signupAdmin: async (adminInfo) => {
    try {
      const url = ApiConstant.SIGNUP_ADMIN;
      const { status, data, message } = await $http.post(url, adminInfo);
      if (status === 200) {
        return data;
      }
      throw message;
    } catch (error) {
      return false;
    }
  },
  /**
   * 관리자 생성 API
   * --
   * @param {*} adminInfo
   * @returns
   */
  createAdmin: async (adminInfo) => {
    try {
      const url = ApiConstant.CREATE_ADMIN;
      const { status, data, message } = await $http.post(url, adminInfo);
      if (status === 200) {
        return data;
      }
      throw message;
    } catch (error) {
      return false;
    }
  },
  /**
   * 관리자 체크 API
   * --
   * @returns
   */
  checkInitial: async () => {
    try {
      const url = ApiConstant.CHECK_INITIAL;
      const { status, data, message } = await $http.get(url);
      if (status === 200) {
        return data;
      }
      throw message;
    } catch (error) {
      return false;
    }
  },
};

export default AdminApi;
