import React, { useEffect } from 'react';
import MainRouter from '../Routes';
import { Loading } from 'Components';
import commonSlice from 'Store/reducers/CommonSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';

const AppPresenter = () => {
  const dispatch = useDispatch();
  /* Router */
  /* State */
  const { loading } = useSelector((state) => state.common);

  /* Functions */
  const handleLoading = useCallback(() => {
    dispatch(commonSlice.actions.setLoading(true));
    setTimeout(() => {
      dispatch(commonSlice.actions.setLoading(false));
    }, 1000);
  }, [dispatch]);

  /* Hooks */
  useEffect(() => {
    handleLoading();
  }, [handleLoading]);

  /* Render */
  return (
    <>
      <Loading loading={loading} />
      <MainRouter />
    </>
  );
};

export default AppPresenter;
