import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  session: false,
  page_title: '대시보드',
  date: '',
  alertList: [],
};

const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setPageTitle(state, action) {
      state.page_title = action.payload;
    },
    addAlert(state, action) {
      const temp = state.alertList.slice();
      temp.push(action.payload);
      state.alertList = temp;
    },
    removeAlert(state, action) {
      const temp = state.alertList.filter((item) => {
        const { id } = item;
        return id !== action.payload;
      });
      state.alertList = temp;
    },
    setDate(state, action) {
      state.date = action.payload;
    },
  },
});

export default commonSlice;
