import { DeviceApi } from 'API';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import DevicePresenter from './DevicePresenter';

const DeviceContainer = () => {
  /* Router */
  /* State */
  const [deviceList, setDeviceList] = useState([]);
  const [deviceQty, setDeviceQty] = useState(0);
  /* Functions */
  /**
   * 기기 정보 조회
   * --
   */
  const handleDeviceList = useCallback(async () => {
    const result = await DeviceApi.getDeviceList();
    if (result) {
      setDeviceQty(result.length);
      const theaterList = _.uniqBy(result, 'theater_id');
      const rr = theaterList.map((item) => {
        const temp = result.filter(
          (subItem) => subItem.theater_id === item.theater_id
        );
        return { ...item, devices: temp };
      });
      setDeviceList(rr);
      return true;
    }
    return false;
  }, []);
  /* Hooks */
  useEffect(() => {
    handleDeviceList();
  }, [handleDeviceList]);

  /* Render */
  return <DevicePresenter deviceList={deviceList} deviceQty={deviceQty} />;
};

export default DeviceContainer;
