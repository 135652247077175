import { PageTitle } from 'Components';
import React from 'react';
import { ShowItem } from './components';
import './show.css';

const ShowPresenter = ({ showList }) => {
  /* Router */
  /* State */
  /* Functions */
  /* Hooks */
  /* Render */
  const showRender = showList.map((item) => {
    const { show_id } = item;
    return <ShowItem key={show_id} show={item} />;
  });
  return (
    <div className="show-container">
      <PageTitle title="공연 목록" />
      {showList.length === 0 && <div className="empty">목록이 없습니다.</div>}
      {showRender}
    </div>
  );
};

export default ShowPresenter;
