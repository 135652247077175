import { NoticeApi } from 'API';
import React from 'react';
import { useEffect } from 'react';
import { useCallback } from 'react';
import { useState } from 'react';
import NoticePresenter from './NoticePresenter';

const NoticeContainer = () => {
  /* Router */
  /* State */
  const [noticeList, setNoticeList] = useState([]);

  /* Functions */
  const handleNoticeList = useCallback(async () => {
    const result = await NoticeApi.getNoticeList();
    if (result) {
      setNoticeList(result);

      return true;
    }
    return false;
  }, []);

  /* Hooks */
  useEffect(() => {
    handleNoticeList();
  }, [handleNoticeList]);

  /* Render */
  return <NoticePresenter noticeList={noticeList} />;
};

export default NoticeContainer;
