import React, { useState, useCallback, useRef } from 'react';
import { PaymentApi } from 'API';
import { BUCKET_URL } from 'Utils';
import LOGO from 'Assets/logo.png';
import './paymentitem.css';

const PaymentItem = ({ payment }) => {
  const ref = useRef();

  const { show_title, show_thumbnail, payments, success, pending } = payment;
  const [isCollapse, setIsCollapse] = useState(false);
  const handleFold = useCallback(() => {
    if (!ref.current) {
      return;
    }

    if (isCollapse) {
      ref.current.style.height = 0;
      ref.current.style.borderBottom = 'none';
    } else {
      const d = payments.length;
      ref.current.style.height = `${d * 213}px`;
      ref.current.style.borderBottom = '1px solid #c4c4c4';
    }
    setIsCollapse(!isCollapse);
  }, [isCollapse, payments.length]);
  const handleSuccessClick = async (payment_id) => {
    const confirm = window.confirm('결제가 완료 되었나요?');
    if (confirm) {
      try {
        const data = {
          payment_id: payment_id,
          payment_status: 'SUCCESS',
        };
        await PaymentApi.updatePayment(data);
        window.alert('정산이 완료되었습니다. 새로고침 해주세요.');
      } catch (error) {
        console.error('정산 실패:', error.response.data);
        window.alert('정산에 실패했습니다.');
      }
    }
  };
  // Rest of your component logic...

  const paymentRender = payments.map((item) => {
    const {
      audience_nm,
      audience_tel,
      payment_qty,
      payment_status,
      payment_id,
      payment_price,
      show_price,
      prepayment_price,
    } = item;
    let status = '';
    let statusColor = '';
    let display = '';
    if (payment_status == 'PENDING') {
      status = '결제 대기';
      statusColor = '#000000';
    } else {
      status = '결제 완료';
      statusColor = '#ff0000'; // Set the color for '정산 완료'
      display = 'none';
    }
    return (
      <div className='payment-item' key={payment_id}>
        <div className='payment-audience_title'>관람객 정보</div>
        <div className='payment-grade'>관객 명 : {audience_nm}</div>
        <div className='payment-grade'>전화 번호 : {audience_tel}</div>
        <div className='payment-grade'>웃음 단가 : {show_price} 원</div>
        <div className='payment-grade'>웃음 횟수 : {payment_qty} 회</div>
        <div className='payment-grade'>총 결제 금액 : {payment_price} 원</div>
        <div className='payment-nm'>
          결제 상태 :{' '}
          <span className='payment_status' style={{ color: statusColor }}>
            {status}
          </span>
        </div>

        <div className='payment-delete' style={{ display: display }}>
          <input
            type='button'
            className='payment-delete-button'
            onClick={() => handleSuccessClick(payment_id)}
            value={'결제 완료'}
          />
        </div>
      </div>
    );
  });
  return (
    <div className='payment-item-container'>
      <div className='payment-header' onClick={handleFold}>
        {show_title}
        <div>결제 완료 : {success} 명</div>
        <div>결제 대기 : {pending} 명</div>
        <div>총 인원 : {payments.length} 명</div>
      </div>
      <div className='payment-content' ref={ref}>
        {/* {payment_ssaid} */}
        {paymentRender}
      </div>
    </div>
  );
};

export default PaymentItem;
