import React, { useState } from 'react';
import { useCallback } from 'react';
import { useRef } from 'react';
import { BUCKET_URL, stringToMoneyFormat } from 'Utils';
import './show-item.css';
import LOGO from 'Assets/logo.png';
import moment from 'moment';
import { ShowApi } from 'API';
const ShowItem = ({ show }) => {
  const ref = useRef();
  /* Router */
  /* State */
  const [isCollapse, setIsCollapse] = useState(false);

  const [data, setData] = useState(show);
  const [isEditing, setIsEditing] = useState(false);
  // 시작 날짜와 시간
  const startDate = moment(data?.show_start_date).utcOffset(9 * 60);
  const endDate = moment(data?.show_end_date).utcOffset(9 * 60); // UTC+9로 변환

  /* Functions */
  const handleFold = useCallback(() => {
    if (!ref.current) {
      return;
    }
    if (isCollapse) {
      ref.current.style.height = 0;
      ref.current.style.borderBottom = 'none';
    } else {
      const d = data?.show_desc.split('\n').length;
      ref.current.style.height = `${d * 25}px`;
      ref.current.style.borderBottom = '1px solid #c4c4c4';
    }
    setIsCollapse(!isCollapse);
  }, [isCollapse, data?.show_desc]);
  /**
   * 데이터 바인딩 함수
   * --
   */
  const handleChange = (t, v, callback = null) => {
    let newData = { ...data };

    newData[t] = v;
    const d = {
      ...newData,
    };
    setData(d);

    if (callback) callback(v);
  };
  //공연삭제
  const handleDeleteClick = async () => {
    const confirm = window.confirm('정말 삭제하시겠습니까?');

    if (confirm) {
      try {
        await ShowApi.deleteShow(data?.show_id);
        window.alert('삭제가 완료되었습니다. 새로고침 해주세요.');
      } catch (error) {
        console.error('삭제 실패:', error.response.data);
        window.alert('삭제를 실패했습니다.');
      }
    }
  };
  //수정
  const handleSaveClick = async () => {
    const confirm = window.confirm('변경사항을 저장하시겠습니까?');
    if (confirm) {
      const {
        show_start_date,
        show_end_date,
        show_id,
        show_title,
        prepayment_price,
        show_price,
      } = data;
      const startDate = moment(show_start_date).utcOffset(-9 * 60); // UTC-9로 변환,
      const endDate = moment(show_end_date).utcOffset(-9 * 60); // UTC-9로 변환,
      await ShowApi.updateShow({
        show_start_date: startDate,
        show_end_date: endDate,
        show_id,
        show_title,
        prepayment_price: parseInt(prepayment_price),
        show_price: parseInt(show_price),
      });
    }
    setIsEditing(false);
  };
  /* Hooks */
  /* Render */
  return (
    <div className='show-item-container'>
      <div className='show-item-header'>
        <div className='show-thumbnail' onClick={handleFold}>
          {data?.show_thumbnail ? (
            <img
              src={`${BUCKET_URL}/${data?.show_thumbnail}`}
              alt={data?.show_id}
            />
          ) : (
            <img src={LOGO} alt={data?.show_id} />
          )}
        </div>
        <div className='show-info' onClick={handleFold}>
          {isEditing ? (
            <>
              <div className='show-title'>
                <input
                  type='text'
                  value={data?.show_title}
                  onChange={(e) => handleChange('show_title', e.target.value)}
                />
              </div>
              <div className='theater-nm'>{data?.theater?.theater_nm}</div>
              <div className='show-date'>
                <input
                  type='datetime-local'
                  name='show_start_date'
                  id='show_start_date'
                  value={data?.show_start_date}
                  onChange={(e) =>
                    handleChange('show_start_date', e.target.value)
                  }
                  placeholder='공연시작일자(시간)'
                />{' '}
                ~{' '}
                <input
                  type='datetime-local'
                  name='show_end_date'
                  id='show_end_date'
                  value={data?.show_end_date}
                  onChange={(e) =>
                    handleChange('show_end_date', e.target.value)
                  }
                  placeholder='공연종료일자(시간)'
                />
              </div>
              <div className='show-prepayment'>
                <strong>사전결제</strong>:{' '}
                <input
                  type='text'
                  value={data?.prepayment_price}
                  onChange={(e) =>
                    handleChange('prepayment_price', e.target.value)
                  }
                />
              </div>
              <div className='show-price'>
                <strong>웃음가격</strong>:{' '}
                <input
                  type='text'
                  value={data?.show_price}
                  onChange={(e) => handleChange('show_price', e.target.value)}
                />
              </div>
            </>
          ) : (
            <>
              <div className='show-title'>{data?.show_title}</div>
              <div className='theater-nm'>{data?.theater?.theater_nm}</div>
              <div className='show-date'>
                <strong>공연일정</strong>:{' '}
                {startDate.format('YYYY-MM-DD HH:mm')} ~{' '}
                {endDate.format('YYYY-MM-DD HH:mm')}
              </div>
              <div className='show-prepayment'>
                <strong>사전결제</strong>:{' '}
                {stringToMoneyFormat(data?.prepayment_price)}
              </div>
              <div className='show-price'>
                <strong>웃음가격</strong>:{' '}
                {stringToMoneyFormat(data?.show_price)}
              </div>
            </>
          )}
        </div>
        {isEditing ? (
          <div>
            <input
              type='button'
              value='저장'
              className='show-button'
              onClick={() => handleSaveClick()}
            />
          </div>
        ) : (
          <input
            type='button'
            value='수정'
            className='show-button'
            onClick={() => setIsEditing(!isEditing)}
          />
        )}
        <input
          type='button'
          value='삭제'
          className='show-button del'
          onClick={handleDeleteClick}
        />
      </div>
      <div className='show-item-content' ref={ref}>
        {data?.show_desc}
      </div>
    </div>
  );
};

export default ShowItem;
