import React from 'react';
import './loading.css';

const Loading = ({ loading = true }) => {
  /* Router */
  /* State */
  /* Functions */
  /* Hooks */
  /* Render */
  return loading ? (
    <div className="loading-container">
      <div className="loading-box">
        <div className="loading-text">페이지를 로드중입니다.</div>
      </div>
    </div>
  ) : (
    ''
  );
};

export default Loading;
