import { ImageUpload, PageTitle } from 'Components';
import Slider from 'rc-slider';
import React, { useEffect } from 'react';
import { useState } from 'react';
import './theater-register.css';
import 'rc-slider/assets/index.css';
import { v4 } from 'uuid';
import { getCookie } from 'Utils';

const marks = {
  0: <strong>0</strong>,
  50: <strong>50</strong>,
  100: <strong>100</strong>,
  200: <strong>200</strong>,
  300: <strong>300</strong>,
  500: <strong>500</strong>,
  750: <strong>750</strong>,
  1000: <strong>1000</strong>,
};

const TheaterRegisterPresenter = ({ createTheater, uploadFile }) => {
  /* Router */
  /* State */
  const initialState = {
    theater_nm: '',
    theater_desc: '',
    theater_addr: '',
    theater_min: '',
    theater_max: '',
    theater_thumbnail: '',
  };

  const [theaterInfo, setTheaterInfo] = useState(initialState);
  const [theaterAddr, setTheaterAddr] = useState();
  const [range, setRange] = useState([0, 0]);
  const [daumPostCode, setDaumPostCode] = useState(null);
  const [preview, setPreview] = useState();

  const s = JSON.parse(getCookie('admin'));

  /* Functions */
  /**
   * 공연장 정보 핸들러
   * --
   * @param {*} e
   */
  const handleTheaterInfo = (e) => {
    setTheaterInfo({ ...theaterInfo, [e.target.name]: e.target.value });
  };
  /**
   * 공연장 등록 핸들러
   * --
   * @param {*} e
   * @returns
   */
  const handleCreateTheater = async (e) => {
    e.preventDefault();
    const postData = {
      ...theaterInfo,
      admin_id: s.admin_id,
    };
    const result = await createTheater(postData);
    if (result) {
      setTheaterInfo(initialState);
      return true;
    }
    return false;
  };

  /**
   * 인원수 핸들러
   * --
   * @param {*} e
   */
  const handleRange = (e) => {
    setRange(e);
    setTheaterInfo({ ...theaterInfo, theater_min: e[0], theater_max: e[1] });
  };

  /**
   * 인원수 텍스트 핸들러
   * --
   * @param {*} e
   */
  const handleTextRange = (e) => {
    if (e.target.name === 'min') {
      const temp = [e.target.value, range[1]];
      setTheaterInfo({ ...theaterInfo, theater_min: e.target.value });
      setRange(temp);
    } else {
      const temp = [range[0], e.target.value];
      setTheaterInfo({ ...theaterInfo, theater_max: e.target.value });
      setRange(temp);
    }
  };

  const handleUploadFile = async (file) => {
    const url = `theater/${v4()}`;
    const form = new FormData();
    form.append('file_path', url);
    form.append('file', file);
    const result = await uploadFile(form);
    if (result) {
      setPreview(result);
      return true;
    }
    return false;
  };

  /**
   * 주소 핸들러
   * --
   * @param {*} error
   */
  const handleError = (error) => {
    // console.log(error);
  };

  /**
   * 다음 주소 열기 핸들러
   * --
   */
  const openPostCodePopup = () => {
    if (daumPostCode) {
      setTheaterInfo({ ...theaterInfo, theater_addr: '' });
      setTheaterAddr();
      daumPostCode.open();
    }
  };

  /**
   * 다음 주소 초기화
   * --
   * @param {*} e
   */
  const initiate = (e) => {
    window.daum.postcode.load(() => {
      setDaumPostCode(
        new window.daum.Postcode({
          oncomplete: (data) => {
            const { roadAddress, bname, zonecode, buildingName } = data;
            setTheaterAddr(
              `${roadAddress} ${buildingName}(${bname}, ${zonecode})`
            );
          },
        })
      );
    });
  };

  /* Hooks */
  useEffect(() => {
    const script = document.createElement('script');
    script.src =
      'https://t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js';
    script.onload = (e) => initiate(e);
    script.onerror = (error) => handleError(error);
    script.id = 'daum-post-code-api';
    document.body.appendChild(script);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!theaterAddr) {
      return;
    }

    if (theaterInfo.theater_addr) {
      return;
    }
    setTheaterInfo({ ...theaterInfo, theater_addr: theaterAddr });
    setTheaterAddr();
  }, [theaterAddr, theaterInfo]);

  useEffect(() => {
    if (!preview) {
      return;
    }
    if (theaterInfo.theater_thumbnail) {
      return;
    }
    setTheaterInfo({ ...theaterInfo, theater_thumbnail: preview });
  }, [preview, theaterInfo]);

  /* Render */
  return (
    <div className="theater-register-container">
      <PageTitle title="공연장 등록" />
      <form className="theater-form" onSubmit={handleCreateTheater}>
        <div className="input-form">
          <label>사진</label>
          <ImageUpload upload={handleUploadFile} preview={preview} />
        </div>
        <div className="input-form">
          <label htmlFor="theater_nm">공연장</label>
          <input
            type="text"
            id="theater_nm"
            name="theater_nm"
            className="border"
            placeholder="공연장 이름"
            value={theaterInfo.theater_nm}
            onChange={handleTheaterInfo}
          />
        </div>
        <div className="input-form">
          <label htmlFor="theater_addr">주소</label>
          <input
            type="text"
            className="theater_addr"
            id="theater_addr"
            name="theater_addr"
            placeholder="주소"
            value={theaterInfo.theater_addr}
            onClick={openPostCodePopup}
          />
        </div>
        <div className="input-form">
          <label htmlFor="theater_desc">설명</label>
          <textarea
            name="theater_desc"
            id="theater_desc"
            cols="30"
            rows="15"
            placeholder="공연장 설명"
            value={theaterInfo.theater_desc}
            onChange={handleTheaterInfo}
          />
        </div>
        <div className="input-form">
          <label htmlFor="theater_range">인원</label>
          <div className="theater-range">
            <Slider
              allowCross={false}
              count
              defaultValue={[0, 50]}
              min={0}
              max={1000}
              step={50}
              range
              onChange={handleRange}
              pushable
              marks={marks}
              value={range}
            />
          </div>
          {/* <input type="range" name="theater_range" id="theater_range" /> */}
        </div>
        <div className="input-form">
          <label htmlFor="" />
          <div className="theater_range_text">
            <input
              type="text"
              name="min"
              value={range[0]}
              onChange={handleTextRange}
            />{' '}
            ~
            <input
              type="text"
              name="max"
              value={range[1]}
              onChange={handleTextRange}
            />
          </div>
        </div>
        <div className="input-form">
          <input type="submit" value="등록" />
        </div>
      </form>
    </div>
  );
};

export default TheaterRegisterPresenter;
