import React, { useState } from 'react';
import { useCallback } from 'react';
import { useRef } from 'react';
import { PaymentApi } from 'API';
import './searchitem.css';

const SearchItem = ({ search }) => {
  const ref = useRef();
  /* Router */
  /* State */
  const {
    payment_id,
    audience_audience_nm,
    audience_audience_tel,
    payment_price,
    payment_status,
    payment_title,
    payment_qty,
  } = search;
  console.log(search);

  const [isCollapse, setIsCollapse] = useState(false);
  /* Functions */
  const handleCollapse = useCallback(() => {
    if (!ref.current) {
      return;
    }
    // if (payment_status === 'SUCCESS') {
    //   return;
    // }
    if (isCollapse) {
      ref.current.style.height = '0';
      ref.current.style.borderBottom = 'none';
    } else {
      const d = payment_id.split('\n').length + 1;
      ref.current.style.height = `${d * 120}px`;
      ref.current.style.borderBottom = '1px solid #c4c4c4;';
    }
    setIsCollapse(!isCollapse);
  }, [isCollapse, payment_status]);

  const handleSuccessClick = async (payment_id) => {
    const confirm = window.confirm('결제가 완료 되었나요?');
    if (confirm) {
      try {
        const data = {
          payment_id: payment_id,
          payment_status: 'SUCCESS',
        };
        await PaymentApi.updatePayment(data);
        window.alert('정산이 완료되었습니다. 새로고침 해주세요.');
      } catch (error) {
        console.error('정산 실패:', error.response.data);
        window.alert('정산에 실패했습니다.');
      }
    }
  };
  /* Hooks */
  /* Render */
  let status = '';
  let statusColor = '';
  let display = '';
  if (payment_status == 'PENDING') {
    status = '결제 대기';
    statusColor = '#000000';
  } else {
    status = '결제 완료';
    statusColor = '#ff0000'; // Set the color for '정산 완료'
    display = 'none';
  }
  return (
    <>
      <div className='payment-item' onClick={handleCollapse}>
        <div className='show-info'>
          <div className='audience-nm'>
            {audience_audience_nm} <span>님</span>
          </div>
          <div className='audience-tel'>{audience_audience_tel}</div>
          <div className='payment-title'>{payment_title}</div>
        </div>
        <div className='payment-info'>
          <div className='payment-status' style={{ color: statusColor }}>
            {status}
          </div>
          <div className='payment-price'>{payment_price}</div>
        </div>
      </div>
      <div className='collapse-item' ref={ref}>
        <div className='payment-title'>공연 명 : {payment_title}</div>
        <div className='audience-nm'>고객 명 : {audience_audience_nm} 님</div>
        <div className='audience-nm'>전화 번호 : {audience_audience_tel}</div>
        <div className='audience-nm'>웃음 횟수 : {payment_qty}</div>
        <div className='audience-nm'>총 금액 : {payment_price}</div>
        <div className='payment-status'>
          결제 상태 : <span style={{ color: statusColor }}>{status}</span>
          <div className='payment-complate' style={{ display: display }}>
            <input
              type='button'
              className='payment-complate-button'
              onClick={() => handleSuccessClick(payment_id)}
              value={'결제 완료'}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default SearchItem;
