import { NoticeApi } from 'API';
import React, { useEffect } from 'react';
import { useCallback } from 'react';
import { useState } from 'react';
import ENoticePresenter from './ENoticePresenter';

const ENoticeContainer = () => {
  /* Router */
  /* State */
  const [noticeList, setNoticeList] = useState([]);
  /* Functions */
  const handleNoticeList = useCallback(async () => {
    const result = await NoticeApi.getNoticeList();
    if (result) {
      setNoticeList(result);
      return true;
    }
    return false;
  }, []);

  /* Hooks */
  useEffect(() => {
    handleNoticeList();
  }, [handleNoticeList]);

  /* Render */
  return <ENoticePresenter noticeList={noticeList} />;
};

export default ENoticeContainer;
