import { AdminApi } from 'API';
import React, { useEffect } from 'react';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import commonSlice from 'Store/reducers/CommonSlice';
import { getCookie, setCookie, writeBuffer } from 'Utils';
import HomePresneter from './HomePresneter';

const HomeContainer = () => {
  const dispatch = useDispatch();
  /* Router */
  const navigate = useNavigate();
  /* State */
  /* Functions */
  /**
   * 관리자 로그인
   * --
   */
  const handleLogin = async (loginInfo) => {
    const result = await AdminApi.signinAdmin(loginInfo);
    dispatch(commonSlice.actions.setLoading(true));
    if (result) {
      setTimeout(() => {
        dispatch(commonSlice.actions.setLoading(false));
      }, 1000);
      const { access_token, ...adminInfo } = result;

      setCookie('bicf_token', access_token);
      setCookie(
        'admin',
        JSON.stringify({ ...adminInfo, admin_nm: writeBuffer(result.admin_nm) })
      );
      navigate('/dashboard');
      return true;
    } else {
      setTimeout(() => {
        dispatch(commonSlice.actions.setLoading(false));
      }, 1000);
      return false;
    }
  };

  /**
   * 세션 체크
   * --
   */
  const checkLogin = useCallback(() => {
    const session = getCookie('bicf_token');

    if (session) {
      navigate('/dashboard');
      return;
    }
  }, [navigate]);

  /* Hooks */
  useEffect(() => {
    checkLogin();
  }, [checkLogin]);

  /* Render */
  return <HomePresneter login={handleLogin} />;
};

export default HomeContainer;
