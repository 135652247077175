import { SearchItem } from './components';
import React, { useState } from 'react';
import './search.css';

const SearchPresenter = ({ paymentList, search }) => {
  /* Router */
  /* State */
  const [keyword, setKeyword] = useState('');
  /* Functions */
  const handleKeyword = (e) => {
    setKeyword(e.target.value);
  };
  const handleSearch = async (e) => {
    e.preventDefault();
    const result = await search(keyword);
    if (result) {
      return true;
    }
    return false;
  };
  /* Hooks */
  /* Render */
  const paymentRender =
    paymentList &&
    paymentList.map((item) => {
      const { payment_id } = item;
      return <SearchItem key={payment_id} search={item} />;
    });
  return (
    <div className='search-container'>
      <h1>결제 검색</h1>
      <form onSubmit={handleSearch} className='search-box'>
        <input
          type='text'
          name='keyword'
          id='keyword'
          onChange={handleKeyword}
          placeholder='검색어를 입력하세요.(이름,번호)'
        />
        <input type='submit' value='검색' />
      </form>
      <div className='payment-box'>
        {paymentList
          ? paymentList.length === 0
            ? '검색 결과가 없습니다.'
            : paymentRender
          : '전화번호 및 이름으로 검색해주세요.'}
      </div>
    </div>
  );
};

export default SearchPresenter;
