import { ApiManager } from '../../Utils';
import ApiConstant from '../ApiConstant';

const $http = new ApiManager();

const DeviceApi = {
  /**
   * 기기 정보 생성
   * --
   * @param {*} deviceInfo
   * @returns
   */
  createDevice: async (deviceInfo) => {
    try {
      const url = ApiConstant.CREATE_DEVICE;
      const { status, data, message } = await $http.post(url, deviceInfo);
      if (status === 200) {
        return data;
      }
      throw message;
    } catch (error) {
      return false;
    }
  },
  /**
   * 기기 정보 목록 조회
   * --
   * @returns
   */
  getDeviceList: async () => {
    try {
      const url = ApiConstant.GET_DEVICE_LIST;
      const { status, data, message } = await $http.get(url);
      if (status === 200) {
        return data;
      }
      throw message;
    } catch (error) {
      return false;
    }
  },
  /**
   * 기기 상세 정보 조회
   * --
   * @param {*} device_id
   * @returns
   */
  getDevice: async (device_id) => {
    try {
      const url = ApiConstant.GET_DEVICE.replace(':device_id', device_id);
      const { status, data, message } = await $http.get(url);
      if (status === 200) {
        return data;
      }
      throw message;
    } catch (error) {
      return false;
    }
  },
  /**
   * 공연장별 기기 정보 목록 조회
   * --
   * @param {*} theater_id
   * @returns
   */
  getDeviceByTheater: async (theater_id) => {
    try {
      const url = ApiConstant.GET_DEVICE_BY_THEATER.replace(
        ':theater_id',
        theater_id
      );
      const { status, data, message } = await $http.get(url);
      if (status === 200) {
        return data;
      }
      throw message;
    } catch (error) {
      return false;
    }
  },
  /**
   * 기기 정보 수정
   * --
   * @param {*} deviceInfo
   * @returns
   */
  updateDevice: async (deviceInfo) => {
    try {
      const url = ApiConstant.UPDATE_DEVICE;
      const { status, data, message } = await $http.put(url, deviceInfo);
      if (status === 200) {
        return data;
      }
      throw message;
    } catch (error) {
      return false;
    }
  },
  /**
   * 기기 정보 삭제
   * --
   * @param {*} device_id
   * @returns
   */
  deleteDevice: async (device_id) => {
    try {
      const url = ApiConstant.DELETE_DEVICE.replace(':device_id', device_id);
      const { status, data, message } = await $http.delete(url);
      if (status === 200) {
        return data;
      }
      throw message;
    } catch (error) {
      return false;
    }
  },
};

export default DeviceApi;
