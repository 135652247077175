import React, { useCallback, useState } from 'react';
import { useRef } from 'react';
import './dropdown.css';
const Dropdown = ({ title, action1 }) => {
  const divRef = useRef();
  /* Router */
  /* State */
  const [isOpen, setIsOpen] = useState(false);
  /* Functions */
  const handleDrop = useCallback(() => {
    if (divRef.current === null) {
      return;
    }
    if (isOpen) {
      divRef.current.style.height = '0';
      divRef.current.style.border = 'none';
    } else {
      divRef.current.style.height = `50px`;
      divRef.current.style.border = `1px solid #1a73e8`;
    }
    setIsOpen(!isOpen);
  }, [isOpen]);
  /* Hooks */
  /* Render */
  return (
    <div className="dropdown-container">
      <div className="dropdown-title" onClick={handleDrop}>
        {title} ▾
      </div>
      <div className="dropdown-list" ref={divRef}>
        <div className="dropdown-item" onClick={action1}>
          로그아웃
        </div>
      </div>
    </div>
  );
};

export default Dropdown;
