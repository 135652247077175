import { AdminApi } from 'API';
import React, { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import InitialAdminPresenter from './InitialAdminPresenter';

const InitialAdminContainer = () => {
  /* Router */
  const navigate = useNavigate();
  /* State */

  /* Functions */
  /**
   * 관리자 초기 세팅 확인
   * --
   */
  const handleCheckInitial = useCallback(async () => {
    const result = await AdminApi.checkInitial();
    if (result) {
      navigate('/');
      return false;
    }
    return true;
  }, [navigate]);

  /**
   * 회원가입 요청
   * --
   * @param {*} adminInfo
   * @returns
   */
  const handleSignup = async (adminInfo) => {
    const result = await AdminApi.signupAdmin(adminInfo);
    if (result) {
      navigate('/');
      return true;
    }
    return false;
  };
  /* Hooks */
  useEffect(() => {
    handleCheckInitial();
  }, [handleCheckInitial]);

  /* Render */
  return <InitialAdminPresenter signup={handleSignup} />;
};

export default InitialAdminContainer;
