import { PageTitle } from 'Components';
import React from 'react';
import { useState } from 'react';
import './noticeregister.css';

const NoticeRegisterPresenter = ({ createNotice }) => {
  /* Router */
  /* State */
  const initialState = {
    notice_title: '',
    notice_content: '',
  };
  const [noticeInfo, setNoticeInfo] = useState(initialState);
  /* Functions */
  /**
   * 공지사항 정보 핸들러
   * --
   * @param {*} e
   */
  const handleNotice = (e) => {
    setNoticeInfo({ ...noticeInfo, [e.target.name]: e.target.value });
  };

  /**
   * 공지사항 등록
   * --
   * @param {*} e
   * @returns
   */
  const handleCreateNotice = async (e) => {
    e.preventDefault();
    const result = await createNotice(noticeInfo);
    if (result) {
      setNoticeInfo(initialState);
      return true;
    }
    return false;
  };
  /* Hooks */
  /* Render */
  return (
    <div className="notice-register-container">
      <PageTitle title="공지사항 등록" />
      <form onSubmit={handleCreateNotice} className="notice-form">
        <div className="input-form">
          <label htmlFor="notice_title">제목</label>
          <input
            id="notice_title"
            name="notice_title"
            type="text"
            placeholder="공지사항 제목"
            value={noticeInfo.notice_title}
            onChange={handleNotice}
          />
        </div>
        <div className="input-form">
          <label htmlFor="notice_content">내용</label>
          <textarea
            id="notice_content"
            name="notice_content"
            cols="30"
            rows="20"
            placeholder="공지사항 내용"
            value={noticeInfo.notice_content}
            onChange={handleNotice}
          />
        </div>
        <div className="input-form">
          <input type="submit" value="등록하기" />
        </div>
      </form>
    </div>
  );
};

export default NoticeRegisterPresenter;
